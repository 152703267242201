import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';

import {
    setTicketsStats, fetchTicketsStats, addOpenedTickets, setLogins, addLogins, addClosedTickets,
    fetchDataResumeCash, setDataResumeCash, setOpenedTickets, fetchOpenedTickets, setCC, setAppConfig,
    setLicence, setLocation, setVersionDB, setCCVehicles, setManagerData, updateClosedTickets, updateOpenedTickets, removeOpenedTicket,
    setClosedTickets,
    removeClosedTicket,
    setAuths
} from "../store/data/actions";
import { setDataCash, addDataCash } from "../store/cash/actions"
import { collection, doc, onSnapshot, orderBy, query, where, getDocs, getDoc, limit, startAfter, startAt } from "firebase/firestore";
import { firestore } from "../Firebase/firebaseConfig";
import moment from 'moment';
import "moment/locale/es";
import { last, map, set } from "lodash";


import numeral from 'numeral'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function formatDate(date) {
    const pad = (n) => (n < 10 ? '0' + n : n);
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.0000`;
}



const ListenerDB = (props) => {

    const dispatch = useDispatch();
    const { currentCompany } = props;

    useEffect(() => {
        let unsubscribe;

        const fetchData = () => {
            const userId = currentCompany.id;
            const usersRef = collection(firestore, "users");
            const userIdRef = doc(usersRef, userId);

            try {
                unsubscribe = onSnapshot(userIdRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {

                        // TICKETSTATS
                        const userData = docSnapshot.data();

                        console.log('USER DATA', userData);


                        const ticketsStats = userData?.ticket_stats || [];
                        dispatch(setTicketsStats(ticketsStats));
                        // LICENCE
                        const licence = userData?.licence || {};
                        dispatch(setLicence(licence));
                        // Location
                        const location = userData?.location || {};
                        dispatch(setLocation(location));
                        // APPCONFIG
                        const appConfig = userData?.app_config || {};
                        dispatch(setAppConfig(appConfig));
                        // VERSION_DB
                        const versionDB = userData?.app_config?.version_db || '0.0.0';
                        dispatch(setVersionDB(versionDB));
                        // // ManagerData
                        // const managerData = userData?.managerData || {};
                        // dispatch(setManagerData(managerData));
                        // RESUME CASH 
                        const resumeCashArray = userData?.resume_cash || [];
                        const jsonFormat = resumeCashArray.array
                            .replace(/\(/g, '[') // Reemplaza "(" por "["
                            .replace(/\)/g, ']') // Reemplaza ")" por "]"
                            .replace(/'/g, '"'); // Reemplaza las comillas simples por comillas dobles
                        const dataArray = JSON.parse(jsonFormat);
                        let totalCash = 0;
                        dataArray.forEach((element) => {
                            totalCash += element[2];
                        });
                        dataArray.forEach((element) => {
                            element[3] = parseInt((element[2] / totalCash) * 100);
                        });
                        dataArray.unshift([1, 'TOTAL', totalCash, 100])
                        // Actualiza el estado de Redux con los datos
                        dispatch(setDataResumeCash(dataArray));
                        // dispatch({ type: UPDATE_RESUME_CASH_STATE, payload: dataArray });
                    }
                });


            } catch (error) {
                console.log('Error fetching ticket stats:', error);
            }
        };

        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }

        };

        // No se retorna nada aquí
    }, [props.currentCompany_id, dispatch]);


    ///////////// LOGINS /// SOLO TOMA EL ULTIMO LOGIN //////////////////////
    useEffect(() => {
        let unsubscribe; // Declarar la variable en el ámbito superior
        const fetchData = async () => {
            const userId = currentCompany.id;
            const loginsRef = collection(doc(firestore, 'users', userId), 'logins');
            const fechaLimite = new Date();
            fechaLimite.setDate(fechaLimite.getDate() - 7);
            const q = query(loginsRef, where('created', '>', fechaLimite.toISOString()), orderBy('created', 'desc'), limit(1));
            // Obtener documentos iniciales
            const initialQuerySnapshot = await getDocs(q);
            const initialDocuments = [];
            let last_doc = null;
            initialQuerySnapshot.forEach((doc) => {
                const docData = doc.data();
                if (last_doc == null) {
                    last_doc = docData.created;
                }
                initialDocuments.push(docData);
            });

            dispatch(setLogins(initialDocuments));

            try {
                unsubscribe = onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const loginData = change.doc.data();
                        if (loginData.created > last_doc) {
                            dispatch(addLogins(loginData));
                            toast.info('Nuevo inicio de sesión: ' + loginData.fullName)
                        }


                    });
                });


            } catch (error) {
                console.log('Error fetching logins:', error);
            }

        };

        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }

        };

    }, [props.currentCompany_id]);


    ///////// AUTHS //////////////////
    useEffect(() => {
        let unsubscribe; // Declarar la variable en el ámbito superior
        const fetchData = async () => {
            const authsRef = collection(firestore, "users", currentCompany.id, "auth");
            const q = query(authsRef);
            const docs = await getDocs(q);
            dispatch(setAuths(docs.docs.map(doc => doc.data())));
        }

        fetchData()
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }


        }, [props.currentCompany_id]);

    ///////// TICKETS //////////////

    useEffect(() => {

        const ticketsRef = collection(firestore, "users", currentCompany.id, "tickets");

        const now = new Date();
        const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
        const thirtyDaysAgoString = formatDate(thirtyDaysAgo);

        // Consulta para tickets abiertos
        const qOpen = query(
            ticketsRef,
            where('closed', '==', 0),
            orderBy('init_date', 'desc')
        );

        // Consulta para tickets cerrados
        const qClosed = query(
            ticketsRef,
            where('closed', '==', 1),
            where('init_date', '>=', thirtyDaysAgoString),
            orderBy('init_date', 'desc')
        );

        // Listener para tickets abiertos
        let isInitialLoad = true;
        const unsubscribeOpen = onSnapshot(qOpen, (querySnapshot) => {
            if (isInitialLoad) {
                // Es la carga inicial
                isInitialLoad = false;

                // Procesa los documentos iniciales sin mostrar toasts
                const initialDocuments = [];
                querySnapshot.forEach((doc) => {
                    initialDocuments.push(doc.data());
                });
                dispatch(setOpenedTickets(initialDocuments));
            } else {
                // Maneja los cambios en tiempo real
                querySnapshot.docChanges().forEach((change) => {
                    const ticket = change.doc.data();
                    if (change.type === "added") {
                        // Nuevo ticket abierto después de la carga inicial
                        toast.info("Nuevo ticket abierto: #" + ticket.plate);
                        dispatch(addOpenedTickets(ticket));
                    } else if (change.type === "modified") {
                        // Ticket abierto modificado
                        dispatch(updateOpenedTickets(ticket));
                    } else if (change.type === "removed") {
                        // Ticket abierto eliminado (posiblemente cerrado)
                        dispatch(removeOpenedTicket(ticket));
                    }
                });
            }
        });

        // Listener para tickets cerrados
        let isInitialLoadClosed = true;
        const unsubscribeClosed = onSnapshot(qClosed, (querySnapshot) => {
            if (isInitialLoadClosed) {
                // Es la carga inicial
                isInitialLoadClosed = false;
                // Procesa los documentos iniciales sin mostrar toasts
                const initialDocuments2 = [];
                querySnapshot.forEach((doc) => {
                    initialDocuments2.push(doc.data());
                });
                dispatch(setClosedTickets(initialDocuments2));

            } else {
                // Maneja los cambios en tiempo real
                querySnapshot.docChanges().forEach((change) => {
                    const ticket = change.doc.data();
                    if (change.type === "added") {
                        // Nuevo ticket abierto después de la carga inicial
                        toast.info("Ticket Cerrado: #" + ticket.plate);
                        dispatch(addClosedTickets(ticket));
                    } else if (change.type === "modified") {
                        // Ticket abierto modificado
                        dispatch(updateClosedTickets(ticket));
                    } else if (change.type === "removed") {
                        // Ticket abierto eliminado (posiblemente cerrado)
                        dispatch(removeClosedTicket(ticket));
                    }
                });
            }
        })


        return () => {
            unsubscribeOpen();
            unsubscribeClosed();
        };

    }, [props.currentCompany_id]);





    ///////// CC //////////////

    useEffect(() => {

        const ccRef = collection(firestore, "users", currentCompany.id, "cc");
        const q = query(ccRef, orderBy('fullName', 'asc'))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const cc = [];
            querySnapshot.forEach((doc) => {
                const d = doc.data();
                if (d.closed == 0)
                    cc.push(d);
            });

            dispatch(setCC(cc));
        });

        return () => {
            unsubscribe();
        };
    }, [props.currentCompany.id]);


    ///////// CC_VEHICLES //////////////

    useEffect(() => {

        const ccVehiclesRef = collection(firestore, "users", currentCompany.id, "cc_vehicles");
        const q = query(ccVehiclesRef, orderBy('fullName', 'asc'))

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const cc_vehicles = [];
            querySnapshot.forEach((doc) => {
                const d = doc.data();
                if (d.deleted == 0)
                    cc_vehicles.push(d);
            });

            dispatch(setCCVehicles(cc_vehicles));
        });

        return () => {
            unsubscribe();
        };
    }, [props.currentCompany.id]);









    ///// CASH ///////////////////////



    useEffect(() => {
        let unsubscribe; // Declarar la variable en el ámbito superior

        const fetchDocuments = async () => {
            dispatch(setDataCash([]));

            const cashRef = collection(firestore, "users", props.currentCompany.id, "cash");
            const q = query(cashRef, where('type', '==', 'init'), orderBy('date', 'asc'));
            const querySnapshot = await getDocs(q);

            const quantity = 10
            const num = querySnapshot.docs.length - quantity < 0 ? 0 : querySnapshot.docs.length - quantity;

            const lastInitDoc = querySnapshot.docs[num];

            const qNew = query(cashRef, orderBy('id', 'asc'), startAt(lastInitDoc));

            // Obtener documentos iniciales
            const initialQuerySnapshot = await getDocs(qNew);
            const initialDocuments = [];

            let last_id;
            initialQuerySnapshot.forEach((doc) => {
                const docData = doc.data();
                last_id = docData.id;
                initialDocuments.push(docData);
            });

            dispatch(setDataCash(initialDocuments));

            unsubscribe = onSnapshot(qNew, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const docData = change.doc.data();
                        if (docData.id > last_id) {
                            dispatch(addDataCash(docData));
                            toast.info("Nuevo movimiento de caja " + numeral(docData.value).format('$0,0.00'))
                        }
                        // Nuevo registro añadido a la colección
                    }
                });
            });


        }

        fetchDocuments();

        return () => {
            // Detener la suscripción cuando el componente se desmonte
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [dispatch, props.currentCompany_id]);


    return <ToastContainer limit={5} pauseOnHover={false} position="bottom-right"
        autoClose={5000} theme="colored" pauseOnFocusLoss={false} />
}





const mapStateToProps = (state) => {
    return {
        currentCompany: state.companies.currentCompany,
        dataResumeCash: state.data.dataResumeCash,
        logins: state.data.logins,
        currentCompany_id: state.companies.currentCompany?.id,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTicketsStats: (ticketsStats) => dispatch(setTicketsStats(ticketsStats)),
        fetchDataResumeCash: () => dispatch(fetchDataResumeCash()),
        setLogins: (logins) => dispatch(setLogins(logins)),
        fetchOpenedTickets: () => dispatch(fetchOpenedTickets()),
        setOpenedTickets: (tickets) => dispatch(setOpenedTickets(tickets)),
        setCC: (cc) => dispatch(setCC(cc))
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ListenerDB);