import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { Col, Card, CardBody, CardHeader, Row, Container, CardTitle } from "reactstrap";
import { connect } from 'react-redux';
import moment from 'moment'
import "moment/locale/es";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { collection, doc, onSnapshot, orderBy, query, where, getDocs, getDoc, limit, startAfter, startAt } from "firebase/firestore";
import { firestore } from "../../Firebase/firebaseConfig";
import {
  addLogins, setLogins
} from "../../store/data/actions";

const Sessions = (props) => {
  document.title = "TicketControl Monitor  | Sesiones";
  const dispatch = useDispatch();
  const { logins } = props;


  ///////////// LOGINS //// BUSCA TODOS LOS LOGINS HASTA 30 DIAS. REEMPLAZA LO EXISTENTE DE LISTENING
  useEffect(() => {

    if (!props.currentCompany?.id) {
      return;
    }

    if (props.logins.length > 1) { // EVITA VOLVER A CARGAR SI YA HAY DATOS
      return
    }

    const fetchData = async () => {
      const userId = props.currentCompany.id;
      const loginsRef = collection(doc(firestore, 'users', userId), 'logins');
      const fechaLimite = new Date();
      fechaLimite.setDate(fechaLimite.getDate() - 30);
      const q = query(loginsRef, where('created', '>', fechaLimite.toISOString()), orderBy('created', 'desc'));

      // Obtener documentos iniciales
      const initialQuerySnapshot = await getDocs(q);
      const initialDocuments = [];
      let last_doc = null;
      initialQuerySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (last_doc == null) {
          last_doc = docData.created;
        }
        initialDocuments.push(docData);
      });

      dispatch(setLogins(initialDocuments));

    };

    fetchData();


  }, []);

  if (props.logins.length === 0) {
    return (
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Cargando</CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="TciketControl" breadcrumbItem="Sesiones" /> */}
          <span></span>
          <Row>

            {logins.map((login, index) => {
              // Obtener la fecha actual en formato YYYY-MM-DD
              const currentDate = moment(login.created).format('YYYY-MM-DD');
              // Verificar si es la primera iteración o si la fecha cambia
              const isNewDate = index === 0 || currentDate !== moment(logins[index - 1].created).format('YYYY-MM-DD');

              return (
                <React.Fragment key={index}>
                  {isNewDate && (
                    <Col lg={12}>
                      <Card color='primary'>
                        <CardBody className='py-3'>
                          <h3 className='text-white m-0'>Sesiones del día {moment(login.created).format('dddd DD/MM')}</h3>
                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  <Col xxl={3} xl={3} lg={4} md={4} sm={12} xs={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xl="12">
                            <h3>{login.fullName}</h3>
                            <p className="text-muted mb-2">Fecha de inicio de sesión</p>
                            <h5 className="font-size-16">{moment(login.created).format('DD/MM/YYYY HH:mm:ss')}</h5>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (state) => ({
  logins: state.data.logins,
  currentCompany: state.companies.currentCompany
})

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);